<template>
  <div>
      <!-- <card class="space" style="width: 19rem; padding: 1rem"> -->
        <kinesis-container>
             <!-- <kinesis-element  :strength="15" 
                    type="depth"
                    >  -->
      <card>
        <div class="center">
          <kinesis-element  :strength="20" 
                    type="depth"
                    > 
        <div class="nft">{{name}}</div>
        </kinesis-element>
        <!-- <img slot="image" class="card-img-top" src="img/eth_card.png" alt="Card image cap"/> -->
                       <kinesis-element  :strength="20" 
                    type="depth"
                    > 
        <a :href=img_link target="blank">
        <img :src=nft_img class="card-img-top"> 
        </a>
        </kinesis-element >

       <div class="row justify-content-center">
         <!-- <a :href=data_link target="blank"><p style="fontWeight: bold; color: purple">DATA</p></a> -->
                       <!-- <kinesis-element  :strength="20" 
                    type="depth"
                    >  -->
        <a :href=data_link target="blank" class=""> <base-button class="button_left">Data</base-button></a>
         <!-- </kinesis-element >
                       <kinesis-element  :strength="20" 
                    type="depth"
                    >  -->
        <a :href=contract_link target="blank" class=""> <base-button class="button_right">Contract</base-button></a>
 <!-- </kinesis-element > -->
       </div>
        
        </div>
      </card>
      <!-- </kinesis-element> -->
        </kinesis-container>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';
import BaseNav from './BaseNav.vue';
import Card from './Cards/Card.vue';

let globals = require("../globals.json");


export default {
  components: { Card, BaseNav, BaseButton },
  name: 'TokenBalance',
  props: {
    name: { type: String, default: "NFT!"
    },
    randInt: { type: Number, default : 200
    },
    img_link:{ type: String, default : "https://picsum.photos/200/200"
    },
    img_scr:{ type: String, default : "https://picsum.photos/200/200"
    },
    nft_data:{ type: String, default : ""
    },
    contract:{ type: String, default : ""
    },
  },
  data() {
      return {source: "https://picsum.photos/200/300" }
  },
  computed: {
    nft_img(){
      return this.img_scr
    },
    nft_link(){
      return this.img_link
    },
    data_link(){
      return this.nft_data
    },
    contract_link(){
      if (globals.testnet){
        return "https:///testnet.bscscan.com/address/"+this.contract
      }
      else{
        return "https://bscscan.com/address/"+this.contract
      }
      
    },
    
  }
}
</script>
<style scoped>
.button_right{ 
  /* margin-bottom: -15px;  */
        margin-top:10px;
        margin-left:15%;}
.button_left{ 
  /* margin-bottom: -10px;  */
        margin-top:10px;
        margin-right:15%;
        }
</style>
