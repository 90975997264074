<template>
    <div>
    
      <div>
          <p>Current supply: {{current_supply}}</p>
          <Br/>
          <p>Max supply: 100,000,000</p>
          <Br/>
          <p>Price: TBD</p>
          <Br/>
          <p>Market Cap: TBD</p>

      </div>
    </div>
</template>
<script>

  const mainnet_contracts = require("../../contracts_data.json"); 
  const testnet_contracts = require("../../test_contracts_data.json"); 
  const globals = require("../../globals.json");
  
  let contract_data;

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }

  export default {
      data(){
          return {current_supply : 0}
      },
      async mounted(){
        console.log("elemintTokenomics mounted")
        let total_supply = await this.$store.dispatch("get_elemint_tokenomics",{})
        // console.log("total supply in mounted", total_supply)
        this.current_supply = total_supply / 1e18;
      }
  }
</script>
<style>
</style>