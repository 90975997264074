<template>
    <div class="row">
    <div class="col-lg-12 col-md-12">
        <card class="card" >
          <h4 slot="header" class="card-title">Latest NFTs</h4>
          <div class="table-responsive">
            <nft-table @approve_coin="approve_coin" @mint="callmint"></nft-table>
            
          </div>
        </card>
        </div>
      
  </div>
</template>
<script>
 
  import NftViewer from '../components/NftViewer.vue';
  import NftTable from './Dashboard/NftsTable.vue';
  import NftBox from '../components/NftBox.vue';




import Web3 from 'web3';
import ElemintTokenomics from './Dashboard/ElemintTokenomics.vue';
   
  const mainnet_contracts = require("../contracts_data.json"); 
  const testnet_contracts = require("../test_contracts_data.json"); 
  const globals = require("../globals.json");

  let contract_data;

  const convertObjectToList = (obj) => {
  let new_array = [];
  Object.keys(obj).map(function(key){
   new_array.push([key, obj[key]]);
  });
  return new_array;
  }

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }

  let nft_list = convertObjectToList(contract_data.nfts)

  
  export default {
    components: {

      NftViewer,
      NftBox,
      NftTable,
      ElemintTokenomics,

    },
    data: function () {
      return {
        nft_array: []
      }
    },
    computed: {
      
    },
    methods: {
      async callmint(info){
        console.log("CALLING MINT 5",info.id,info.nft,info.price)


      },
        approve_coin(id){
        console.log("approving coin(3)!: ", id)
       
      }
      
      
    },
    mounted() {

    },
    created() {

    },
  

  };
</script>
<style>
</style>
